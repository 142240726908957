import React from 'react'
import Tile from '../Tile/Tile';
import GradHatIcon from "../../../../../../assets/images/module/grad-hat.svg";
import CertificateViewIcon from "../../../../../../assets/images/module/view-certificate.svg";
import VideoIcon from "../../../../../../assets/images/module/video.svg";
import { useTranslation } from 'react-i18next';
import Moment from "react-moment";


function TileContainer({academy}) {
  const { t } = useTranslation();

  return (
    <div className="tile_container">
          { academy.next_module.name &&
          <Tile
            tile_title={academy.next_module.name}
            tile_header={t("Continue")}
            tile_icon={GradHatIcon}
            style={academy.completed ? { display: "none" } : null}
            academySlug={academy.slug}
            slug={academy.next_module.slug}
          />
          }
          { academy.webinar_session.webinar && academy.webinar_session.date &&
          <Tile
            tile_title={<Moment local format="lll">{academy.webinar_session.date}</Moment>}
            tile_header={academy.webinar_session.webinar?.name}
            tile_icon={VideoIcon}
            style={academy.completed ? { display: "none" } : null}
            academySlug={academy.slug}
            slug={academy.webinar_session.webinar?.slug}
          />
          }
          {
            academy.completed ?
                <Tile
                  tile_title={t("Academy completed")}
                  tile_header={t("View Certificate")}
                  tile_icon={CertificateViewIcon}
                  academySlug={academy.slug}
                  academy={academy}
                  certificate
                />
          : null
          }
    </div>
  )
}

export default TileContainer
