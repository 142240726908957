import React, { useState } from "react";
import SidebarHeader from "./Header/SidebarHeader";

function Sidebar({ name }) {
  const [sideBar, setSideBar] = useState(false);

  return (
    <section className="dashboard__sidebar">
      <div className="pull" onClick={() => setSideBar(!sideBar)}></div>
      <SidebarHeader />
    </section>
  );
}

export default Sidebar;
