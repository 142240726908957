import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import coreService from "../../../../services/core.service";
import AcademyCard from "./AcademyCard/AcademyCard";
import MoreCourses from "./ComingMoreCard/ComingMoreCard";
function MyCourses() {
  /*
    academy_collection
      >next_module
      >webinar_session
  */
  const [academiesData, setAcademiesData] = useState([]);

  useEffect(() => {
    coreService
      .getAcademies()
      .then((res) => setAcademiesData(res.data.records));
  }, []);

  const { t } = useTranslation();

  return (
    <div className="dashboard__container__my_courses__container">
          <h1>{t('My Academies')}</h1>

      {
        academiesData.map((data, i) => {
          return(
            <AcademyCard
              key={i}
              academy={data}
            />
          )
        })
      }
    </div>
  );
}

export default MyCourses;
