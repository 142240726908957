
import React, { useState } from "react";
import CardHeader from "./CardHeader/CardHeader";
import TileContainer from "./TileContainer/TileContainer";

function AcademyCard({academy}) {

  // responsive settings - width 1080px
  const windowWidth = window.innerWidth;

  const accordionState = academy.completed || (!academy.next_module.name && !academy.webinar_session.webinar)

  // when cardView is false the accordion will open and when true it will close
  const [cardView, setCardView] = useState(accordionState)
  
  const isCertificate = academy.next_certificate.name


  const academyCardStyle = {
      height: cardView ? "5rem" : '18.875rem',
      paddingBottom: cardView ? null : '3.125rem'
  }

  // style for window width < 1080px
  const academyCardStyleSmall = {
    // 2 cards if certificate isnt there and 1 card if there
    // 1 card = 8.75rem
    height: cardView ? "6rem" : !cardView && isCertificate === '' && !academy.completed ? `${8.75 * 3 + 2}rem` : `${8.75 * 2 + 1}rem`,
    transition: 'height 250ms ease-in-out',
    marginBottom: '1rem',
  }

  return (
    <div className='academy_card' style={windowWidth < 1080 ? academyCardStyleSmall : academyCardStyle} >
          <div className="container">
            <CardHeader 
              academy = { academy }
              card_view = { cardView }
            />
            <div 
              className="clickable_header"
              onClick={() => setCardView(!cardView)}>
            </div>

            <TileContainer 
              academy = { academy }
            />
          </div>
    </div>
  );
}
export default AcademyCard;
