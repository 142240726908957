import React from "react";
import Header from "../Sidebar/Header/SidebarHeader";

function MobileSidebar() {

  return (
    <div className="dashboard__mobile_sidebar">
      <Header />
    </div>
  );
}

export default MobileSidebar;
