import React from 'react';
import ArrowRight from "../../../../../../assets/images/arrow-right.svg";

const TileContent = ({ tile_icon,
  tile_title,
  tile_header,
  style,
  slug,
  academySlug,
  certificate,
  onClick
}) => {
  return (
    <div
      className="tile_container__tile"
      style={style}
      onClick={() => {
          onClick(academySlug, slug);
      }}
    >
      <img src={tile_icon} alt="" className="tile_icon" />

      <div className="info_wrapper">
        <div className="text_wrapper">
          <h4>{tile_header}</h4>
          <h3>{tile_title}</h3>
        </div>
        <div className="button_wrapper">
          <button>
            <img src={ArrowRight} alt="" />
          </button>
        </div>
      </div>
    </div>
  )
}

export default TileContent;
