export const endpoints = {
  /* Users */
  login: "users/login/",
  manage: "users/manage/",
  refresh: "users/token/refresh/",
  sso_url: "users/authorization/{email}/",
  access_token: "users/access-token/",

  /* Core */
  collections: "v1/academy-collections/",
  collection: "v1/academy-collections/:slug/",
  academies: "v1/academies/",
  academy: "v1/academies/:slug/",
  academyOverview: "v1/academies/:slug/overview/",
  sections: "v1/sections/",
  section: "v1/sections/:slug/",
  modules: "v1/modules/",
  module: "v1/modules/:slug/",
  moduleCompletion: "v1/modules/:slug/complete/",
  webinars: "v1/webinars/",
  webinar: "v1/webinars/:slug/",
  webinarSessions: "v1/webinar-sessions/",
  webinarSession: "v1/webinar-sessions/:slug/",
  webinarSessionCompletion: "v1/webinar-sessions/:slug/complete/",
  moduleVideoThumbnail: "v1/module-video-thumbnail/:videoId/",
  quizzes: "v1/quizzes/",
  quiz: "v1/quizzes/:slug/",
  responses: "v1/responses/",
  response: "v1/responses/:slug/",
  files: "v1/files/",
  file: "v1/files/:slug/",
  certificates: "v1/certificates/",
  certificate: "v1/certificates/:slug/",
  achievements: "v1/achievements/",
  achievement: "v1/achievements/:slug/"
};
