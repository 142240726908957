import React, { useState } from "react";
import Logo from "../../assets/images/logo.svg";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import MobileNav from "./components/MobileNav/MobileNav";
import DesktopNav from "./components/DesktopNav/DesktopNav";
import SkipToContent from "../SkipToContent/SkipToContent";
import { useNavigate } from "react-router-dom";

function Header({ dashboard, calendar, showSkipToContent }) {
  const [navToggle, setNavToggle] = useState(false);

  let navigate = useNavigate()

  return (
    <header className="header">
     {showSkipToContent && <SkipToContent />}
      <img src={Logo} alt="company logo" onClick={() => navigate('/')} style={ {cursor: "pointer"} } />
      <button
        className="header__nav_toggle"
        onClick={() => setNavToggle(!navToggle)}
      >
        {navToggle ? (
          <CloseIcon style={{ color: "#21273c" }} />
        ) : (
          <MenuIcon style={{ color: "#21273c" }} />
        )}
      </button>
      <MobileNav nav_toggle={navToggle} set_nav_toggle={ setNavToggle }/>
      <DesktopNav dashboard={dashboard} calendar={calendar} />
    </header>
  );
}

export default Header;
