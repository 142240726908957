import React, { useEffect, useState, useRef } from "react";

function VideoPlayer({ module, set_video_ended, set_video_started, video_url }) {
    const [data, setData] = useState(module);
    const playerRef = useRef();
    const vimeoPlayerRef = useRef(null);

    useEffect(() => setData(module), [module]);

    useEffect(() => {
        const loadVimeoScript = () => {
            return new Promise((resolve) => {
                if (document.getElementById("vimeo-player-script")) {
                    resolve();
                    return;
                }

                const script = document.createElement("script");
                script.id = "vimeo-player-script";
                script.src = "https://player.vimeo.com/api/player.js";
                script.onload = resolve;
                document.body.appendChild(script);
            });
        };

        const initializeVimeoPlayer = () => {
            if (playerRef.current && !vimeoPlayerRef.current) {
                vimeoPlayerRef.current = new window.Vimeo.Player(playerRef.current, {
                    url: video_url,
                    autoplay: true,
                    controls: true,
                });

                vimeoPlayerRef.current.on('play', () => {
                    set_video_started(true);
                });

                vimeoPlayerRef.current.on('ended', () => {
                    set_video_ended(true);
                    set_video_started(false);
                });

                // Clean up the player when the component unmounts
                return () => {
                    if (vimeoPlayerRef.current) {
                        vimeoPlayerRef.current.off('play');
                        vimeoPlayerRef.current.off('ended');
                        vimeoPlayerRef.current.destroy();
                        vimeoPlayerRef.current = null;
                    }
                };
            }
        };

        loadVimeoScript().then(initializeVimeoPlayer);

        return () => {
            if (vimeoPlayerRef.current) {
                vimeoPlayerRef.current.off('play');
                vimeoPlayerRef.current.off('ended');
                vimeoPlayerRef.current.destroy();
                vimeoPlayerRef.current = null;
            }
        };
    }, [video_url, set_video_ended, set_video_started]);

    useEffect(() => {
        if (vimeoPlayerRef.current) {
            vimeoPlayerRef.current.loadVideo(video_url).catch(error => {
                console.error("Failed to load video:", error);
            });
        }
    }, [video_url]);

    return (
        <div>
            {!data.locked && video_url && (
                <div 
					className="vimeo-player" 
					ref={playerRef}
					data-vimeo-autoplay="true" 
					data-vimeo-fullscreen="true" 
					data-vimeo-picture-in-picture="true" 
					style={{
						padding: "56.25% 0 0 0", /* 16:9 Aspect Ratio */
						position: "relative",
						width: "100%"
					}}
				/>
            )}
        </div>
    );
}

export default VideoPlayer;



