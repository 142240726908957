import React from 'react'
import { Done } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

function CardHeader({academy, card_view}) {
    const windowWidth = window.innerWidth
    let navigate = useNavigate()
    const { t } = useTranslation();

    function openNextLesson() {
        navigate(`/academies/${academy.slug}?module=${academy.next_module.slug}`);
    }

    function openAcademy() {
    navigate(`/academies/${academy.slug}`);
    }

    const headerStyle = {
        borderBottom: card_view ? "1px solid #21273D00" : "1px solid #21273D10"
    }

    const headerStyleSmall = {
        borderBottom: card_view ? "1px solid #21273D00" : "1px solid #21273D10",
        display: 'grid',
        gridTemplateColumns: "auto auto",
        justifyItems: "flex-start",
        alignItems: "flex-start",
        justifyContent: "space-between"
    }

  return (
    <header style={ windowWidth < 1080 ? headerStyleSmall : headerStyle } >
        <h2>{academy.name}</h2>
        <h4 className="progress">
            {
                academy.completed ? t('Completed') :
                (
                    <>
                    <span style={{ color: "#6bcad2", marginRight: '0.25rem' }}>
                        {`${academy.completion_rate}%`}
                    </span>
                    {t("complete")}
                </>
                )
            }
        </h4>
        {
            academy.completed ?
            <h4
                className="revist_course"
                onClick={() => {
                openAcademy();
                }}>
                {t("Revisit course")}
            </h4>
            : academy.next_module.name ?
            <h4
                className="next_lesson"
                onClick={() => {
                openNextLesson();
                }}>
                {t("Go to next lesson")}
            </h4>
            :
            <h4
                className="next_lesson"
                onClick={() => {
                openAcademy();
                }}>
                {t("Go to course")}
            </h4>
        }
        {
            academy.completed ?
            <div className="done_circle">
                <Done style={{fontSize: '1rem'}} />
            </div>
            :
            null
        }
    </header>
  )
}

export default CardHeader
