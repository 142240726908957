import React from "react";
import MyCourses from "./components/MyCourses/MyCourses";
import Sidebar from "./components/Sidebar/Sidebar";
import MobileSidebar from "./components/MobileSidebar/MobileSidebar";
import Header from "../../components/Header/Header";
import BannerImage from '../../assets/images/finger.png'
import { useTranslation } from "react-i18next";

function Dashboard() {
  const { t } = useTranslation();
  return (
    <main className="dashboard">

      <Header dashboard={true} calendar={false} showSkipToContent={true} />
      <MobileSidebar />
      
      <div className="dashboard__container">

        <div className="dashboard__sidebar_container">
          <Sidebar />
        </div>
        <div className="dashboard__container_main">
          <div className="dashboard__container_main__banner">
            <div className="dashboard__container_main__banner_image">
              <span className="blob-1"></span>
              <span className="blob-2"></span>
              <span className="blob-3"></span>
              <span className="blob-4"></span>
              <span className="blob-5"></span>
              <span className="blob-6"></span>
              <span className="blob-7"></span>
              <span className="blob-8"></span>
              <span className="blob-9"></span>
              <span className="blob-10"></span>
              <img src={BannerImage} alt="" className="banner_image"/>
            </div>
            <div className="dashboard__container_main__banner_content">
              <h1>NVisionU</h1>
              <h2>{t("Digital Learning Platform")}</h2>
            </div>
          </div>
          <div id="main-content" className="dashboard__container_main__my_courses">
            <MyCourses />
          </div>
        </div>
        
      </div>

    </main>
  );
}

export default Dashboard;
