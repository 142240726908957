import React from "react";
import UserProfile from "../../../../../components/UserProfile/UserProfile";
import EditIcon from "../../../../../assets/images/edit-icon.svg";
import userService from "../../../../../services/user.service";
import { useTranslation } from "react-i18next";

function SidebarHeader() {
  const user = userService.getUser();
  const { t } = useTranslation();

  const handleEditNavigation = (e) => {
    e.preventDefault();
    window.location.href = "https://office2.nvisionu.com/app.html#/TeamDashboard"
  }


  return (
    <header className="dashboard__sidebar__header">
      <UserProfile
        isThereName={false}
        height="6rem"
        width="6rem"
        fontSize="1.5rem"
      />
      <div className="dashboard__sidebar__header_text_wrapper">
        <h3
          style={{ marginTop: "2rem" }}
        >{`${user.first_name} ${user.last_name}`}</h3>
      </div>
      <button className="edit_button" onClick={(e) => handleEditNavigation(e)}>
        <img src={EditIcon} alt="" />
        <h4>{t("Edit account")}</h4>
      </button>
    </header>
  );
}

export default SidebarHeader;
