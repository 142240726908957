import React, { useState } from "react";
import UserProfile from "../../../UserProfile/UserProfile";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import userService from "../../../../services/user.service";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { KeyboardArrowUp } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

function DesktopNav({ dashboard, calendar }) {
  const [logoutPanel, setLogoutPanel] = useState(false);

  let navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("user");
    window.location.href = "/";
  };

  const { t } = useTranslation();

  const user = userService.getUser();
  return (
    <nav className="header__desktop_nav">
      <div className="header__desktop_nav__links_container">
        <div className="links_wrapper">
          <li
            onClick={() => navigate("/")}
            className={dashboard ? "links link-active" : "links"}
          >
            {t("Dashboard")}
          </li>
          {/*
          <li
            onClick={() => navigate("/calendar")}
            className={calendar ? "links link-active" : "links"}
          >
            {t("Webinars")}
          </li>
          */}
        </div>
      </div>
      <div className="right_wrapper">
        <button
          className="right_wrapper__default"
          onClick={() => setLogoutPanel(!logoutPanel)}
          onBlur={() => setLogoutPanel(false)}
        >
          <UserProfile
            isThereName={true}
            height="40px"
            width="40px"
            fontSize="0.875rem"
            marginRight="0.75rem"
          />
          <span>{user.first_name}</span>
          {logoutPanel ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </button>
        <div
          className="logout_wrapper"
          style={
            logoutPanel
              ? { height: "4.5rem", padding: "1rem 0.5rem" }
              : { height: "0px", padding: "0" }
          }
        >
          <button className="logout_btn" onClick={handleLogout}>
            <span>{t('Logout')}</span>
            <LogoutIcon style={{ fontSize: "1rem" }} />
          </button>
        </div>
      </div>
    </nav>
  );
}

export default DesktopNav;
